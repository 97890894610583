import baseComponent from "@/scripts/baseComponent";
import authApi from '@/api/common/auth';

export default {

    extends: baseComponent,

    props: {

    },

    data() {
        return {
            filterCountry: '',
            confirmText: '',
            listData: {},

            batchPaypal: false,
            enabledPaypal: false,
            minimumDepositPaypal: 100,

            batchStripe: false,
            enabledStripe: false,
            minimumDepositStripe: 10,

            batchMomo: false,
            enabledMomo: false,
            minimumDepositMomo: 25,

            batchNganLuong: false,
            enabledNganLuong: false,
            minimumDepositNganLuong: 25,
        };
    },

    computed: {

    },

    created() {

    },

    mounted() {
        this.getData();
    },

    methods: {

        // Close this pop-up
        closeThisPopup() {
            this.listData = {};
            this.closePopup('system-settings-policy-deposit-limit');
        },

        // Batch
        changedEnabled(val) {
            this.listData.forEach(element => {
                element[`enabled${val}`] = this[`enabled${val}`];
            });
        },
        changedMinimum(val) {
            this.listData.forEach(element => {
                element[`minimumDeposit${val}`] = this[`minimumDeposit${val}`];
            });
        },

        // Restore
        restore() {
            this.listData = JSON.parse(this.defaultValue);
        },

        // Get data
        getData() {
            this.feedStatusLoading(0);
            authApi.policyDepositLimitView()
                .then((res) => {
                    try {
                        this.defaultValue = JSON.stringify(res.data);
                        this.listData = res.data;
                        this.feedStatusLoading(1);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(3);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                // setTimeout(() => {
                                //     location.href = "/login";
                                // }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(3);
                });
        },

        // Edit
        edit() {
            this.feedStatusLoading(0);
            this.listData.forEach(element => {
                element.minimumDepositPaypal = parseFloat(element.minimumDepositPaypal);
                element.minimumDepositStripe = parseFloat(element.minimumDepositStripe);
                element.minimumDepositMomo = parseFloat(element.minimumDepositMomo);
                element.minimumDepositNganLuong = parseFloat(element.minimumDepositNganLuong);
                element.maximumDepositPaypal = parseFloat(element.maximumDepositPaypal);
                element.maximumDepositStripe = parseFloat(element.maximumDepositStripe);
                element.maximumDepositMomo = parseFloat(element.maximumDepositMomo);
                element.maximumDepositNganLuong = parseFloat(element.maximumDepositNganLuong);
            });
            let request = {
                listData: this.listData,
                deviceInformation: JSON.stringify(this.getInformationClient()),
            }
            authApi.policyDepositLimitModify(request)
                .then((res) => {
                    try {
                        this.showSuccessToast(`Successful edit policy deposit limit !`);
                        setTimeout(() => {
                            this.feedStatusLoading(1);
                            this.closePopup('system-settings-policy-deposit-limit');
                        }, 500);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(3);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                // setTimeout(() => {
                                //     location.href = "/login";
                                // }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(3);
                });
        }
    }
} 